import * as React from "react";
import '../App.scss';
import '@csstools/normalize.css';
import Header from "../components/header";
import Footer from "../components/footer";
import Container from '@mui/material/Container';
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider  } from '@mui/material/styles';

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1113,
            xl: 1414,
        },
    },
});
export default function Faq(){
    return(
        <ThemeProvider theme={theme}>
            <Container maxWidth="xl" className={'static-page'}>
                <Header/>
                <Box className="page_article aboutus_article" maxWidth="lg">
                    <Box sx={{padding: '0 0 40px'}}>
                        <h2 className="page_article__subheader"><span className="page_article__subheader_text">What is Stock4Services.com?</span>
                        </h2>
                        <div className="page_article__text">
                            <p>
                                <span className="s4s">Stock4Services.com</span> provides a unique service designed
                                specifically for
                                media publishers and service providers. We offer a way for you to turn your excess ad
                                capacity into
                                equity in emerging companies that are finding it more difficult to find seed and bridge
                                funding, and
                                are looking for creative ways to bootstrap the growth of their businesses. <span
                                className="s4s">Stock4Services.com</span>
                                provides them a bootstrap alternative with your company as a potential beneficiary. Your
                                company
                                provides them with the strategic advertising or other services they need to grow their
                                business and
                                you benefit by participating in the success of their company. Our services present no
                                risk or out of
                                pocket expense to your company. We are only compensated when you are compensated.
                            </p>
                        </div>
                    </Box>
                    <Box sx={{padding: '0 0 40px'}}>
                        <h2 className="page_article__subheader"><span className="page_article__subheader_text">How does Stock4Services.com work?</span>
                        </h2>
                        <div className="page_article__text">
                            <p>
                                After registering and signing-in, you will have access to our comprehensive database of
                                emerging
                                companies looking to exchange equity for your valuable services. Each emerging company
                                provides all
                                the critical information you need to select the best fit for you and includes the
                                following
                                information:
                            </p>
                            <ul className="faq_article__list">
                                <li className="faq_article__list_item">Business description and background</li>
                                <li className="faq_article__list_item">Markets served</li>
                                <li className="faq_article__list_item">Geographic location</li>
                                <li className="faq_article__list_item">Service requirements and objectives</li>
                                <li className="faq_article__list_item">'Elevator pitches', executive summaries and
                                    business plans
                                </li>
                                <li className="faq_article__list_item">Stock4Services.com as a facilitator, providing
                                    you with all the
                                    information you will need to contact these companies and enter into a
                                    services-for-equity
                                    agreement.
                                </li>
                            </ul>
                        </div>
                    </Box>
                    <Box sx={{padding: '0 0 40px'}}>
                        <h2 className="page_article__subheader"><span className="page_article__subheader_text">How does it benefit my company?</span>
                        </h2>
                        <div className="page_article__text">
                            <p>
                                <span className="s4s">Stock4Services.com</span>'s innovative services give your company
                                the opportunity
                                to leverage surplus ad space, ad time or services and turn it into an equity position in
                                one or more
                                emerging companies. And is accomplished without investing one dime. Your company will
                                benefit in the
                                following ways:
                            </p>
                            <ul className="faq_article__list">
                                <li className="faq_article__list_item">Fill idle, non-revenue-producing ad space and
                                    time with
                                    equity-producing ads.
                                </li>
                                <li className="faq_article__list_item">Your support increases the odds of success for an
                                    emerging
                                    company, which in turn increases the value of your held asset: support breeds
                                    success.
                                </li>
                            </ul>
                        </div>
                    </Box>

                    <Box sx={{padding: '0 0 40px'}}>
                        <h2 className="page_article__subheader"><span className="page_article__subheader_text">What’s the cost of your services?</span>
                        </h2>
                        <div className="page_article__text">
                            <p>
                                There are currently no listing fees or other charges for the service providers for using
                                Stock4Services platform to find suitable investment opportunities.
                            </p>
                        </div>
                    </Box>
                </Box>
                <Footer/>
            </Container>
        </ThemeProvider>
    )
}