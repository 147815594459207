import * as React from "react";
import '../App.scss';
import '@csstools/normalize.css';
import Header from "../components/header";
import Footer from "../components/footer";
import Container from '@mui/material/Container';
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider  } from '@mui/material/styles';

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1113,
            xl: 1414,
        },
    },
});

export default function Termsofuse(){
    return(
        <ThemeProvider theme={theme}>
            <Container maxWidth="xl" className={'static-page'}>
                <Header/>
                <Box className="page_article aboutus_article" maxWidth="lg">
                    <div className="content_wrap_wrapper">
                        <article className="page_article terms_article">
                            <h1 className="page_article__header" id="top"><span className="page_article__header_text">Terms of Service</span>
                            </h1>
                            <ul className="terms_article__toc">
                                <li className="terms_article__toc_item"><a href="#terms_and_conditions"
                                                                           className="terms_article__toc_item_link">Terms
                                    and conditions</a>
                                </li>
                                <li className="terms_article__toc_item"><a href="#no_financial_advice"
                                                                           className="terms_article__toc_item_link">No
                                    Financial Advice</a></li>
                                <li className="terms_article__toc_item"><a href="#no_warranty"
                                                                           className="terms_article__toc_item_link">Stock4Services,
                                    Inc. Makes No Warranty</a></li>
                                <li className="terms_article__toc_item"><a href="#liability_limitation"
                                                                           className="terms_article__toc_item_link">Liability
                                    Limitation; Your
                                    Exclusive Remedy</a></li>
                                <li className="terms_article__toc_item"><a href="#acknowledgement_and_agreement"
                                                                           className="terms_article__toc_item_link">ACKNOWLEDGEMENT
                                    AND
                                    AGREEMENT</a></li>
                                <li className="terms_article__toc_item"><a href="#changes_to_the_websites"
                                                                           className="terms_article__toc_item_link">Changes
                                    to the
                                    Stock4Services Websites; Additional Liability Limitation</a></li>
                                <li className="terms_article__toc_item"><a href="#service_fees"
                                                                           className="terms_article__toc_item_link">Service
                                    Fees and Penalties</a></li>
                                <li className="terms_article__toc_item"><a href="#choice_of_legal_forum_selection"
                                                                           className="terms_article__toc_item_link">Choice
                                    of Legal Forum
                                    Selection</a></li>
                            </ul>
                        </article>
                        <article className="page_article terms_article" id="terms_and_conditions">
                            <h2 className="page_article__subheader"><span className="page_article__subheader_text">Terms and Conditions</span>
                            </h2>
                            <div className="page_article__text">
                                <p>
                                    This agreement describes your rights and responsibilities. Please read it carefully.
                                    It is highly
                                    recommended that you consult a licensed financial advisor or broker before making
                                    any and all
                                    investment decisions.
                                </p>
                                <p>
                                    All contents of the service are provided for information purposes only and are
                                    provided without
                                    warranty of any kind. In no event will we be liable for any direct, indirect,
                                    consequential, or
                                    incidental damages arising out of any decision made or action taken by you in
                                    reliance on the
                                    service, whether or not caused in whole or part by our negligence.
                                </p>
                                <p>
                                    You agree that the service should not be interpreted as investment advice, as an
                                    endorsement of any
                                    security, or as an offer to buy or sell any security. We are not a registered broker
                                    dealer, or
                                    financial advisor. The information being exchanged using the Stock4Services system
                                    is related to
                                    investments, and as such, we will require that only qualified Accredited Investors
                                    will be allowed
                                    to subscribe to this service.
                                </p>
                                <p>
                                    We do not provide personal investment advice. Investors are advised not to rely on
                                    any information
                                    contained in the site in the process of making a fully informed investment decision.
                                    There is no
                                    substitute for good, thorough, individual research. This site does not render any
                                    legal or tax
                                    advice. We recommend that you seek the professional individual advice of an
                                    attorney, personal
                                    financial advisor, stockbroker or accountant before buying or selling securities, or
                                    making any
                                    investment decisions. You assume the entire cost and risk of any investing or
                                    trading you choose to
                                    undertake. We do not represent ourselves as qualified investment advisors or
                                    properly licensed
                                    parties. We are a publicly available service that provides general, disinterested
                                    information.
                                </p>
                                <p>
                                    All information provided by the service is obtained from sources believed to be
                                    accurate and
                                    reliable. However, due to the number of sources from which information on the
                                    service is obtained,
                                    and the inherent hazards of electronic distribution, there may be delays, omissions,
                                    or inaccuracies
                                    in such information. We do not warranty the accuracy, completeness, correctness or
                                    fitness for any
                                    particular purpose of the information available through the service. This site does
                                    not make any
                                    offer to solicit, deal in, sell or dispose of any security for valuable
                                    consideration, and does not
                                    give advice for the purpose of buying and selling a security.
                                </p>
                                <p>
                                    Any redistribution of the information, images or intellectual property presented and
                                    or contained
                                    within this website without prior written consent from MANAGEMENT is strictly
                                    prohibited.
                                </p>
                                <div className="terms_article__to_top"><a className="terms_article__to_top_link"
                                                                          href="#top">back to top</a>
                                </div>
                            </div>
                        </article>

                        <article className="page_article terms_article" id="no_financial_advice">
                            <h2 className="page_article__subheader"><span className="page_article__subheader_text">No Financial Advice</span>
                            </h2>
                            <div className="page_article__text">
                                <p>
                                    The information, forms and other resources available on or through this website and
                                    received from or
                                    through this website such as emails and/or newsletters are provided for education
                                    and informational
                                    purposes only, without any express or implied warranty of any kind, including
                                    warranties of
                                    accuracy, completeness, or fitness for any particular purpose. The information
                                    contained in or
                                    provided from or through this website is not intended to be and does not constitute
                                    financial
                                    advice, investment advice, trading advice or any other advice. The information on
                                    this website and
                                    provided from or through this website in the form of emails and/or newsletters is
                                    general in nature
                                    and is not specific to you the User or anyone else. YOU SHOULD NOT MAKE ANY
                                    DECISION, FINANCIAL,
                                    INVESTMENTS, TRADING OR OTHERWISE, BASED ON ANY OF THE INFORMATION PRESENTED ON OR
                                    DELIVERED BY ANY
                                    OF THE PRODUCTS, SERVICES, INTERNET WEBSITES WITHOUT UNDERTAKING INDEPENDENT DUE
                                    DILIGENCE AND
                                    CONSULTATION WITH A PROFESSIONAL BROKER OR COMPETENT FINANCIAL ADVISOR. You agree
                                    that any and all
                                    use of the information and website which you make, is solely at your own risk and
                                    without any
                                    recourse whatsoever to Stock4Services, Inc., its associates, subsidiaries, partners
                                    or content
                                    providers. You understand that you are using any and all information, forms and
                                    other resources
                                    available on or through this website and received from or through this website such
                                    as emails and/or
                                    newsletters AT YOUR OWN RISK.
                                </p>
                                <p>
                                    Stock4Services, Inc. makes no representations or warranties, express or implied, as
                                    to the accuracy,
                                    completeness, or fitness for any purpose or use of the information presented on the
                                    website or made
                                    available from any or all of the products, services, internet websites owned and
                                    operated by
                                    Stock4Services, Inc. The information may not in all cases be current and it is
                                    subject to continuous
                                    change. Accordingly, you should not rely on any of the information as authoritative
                                    or substitute
                                    for the exercise of your own skill and judgment in making any investment or other
                                    decision.
                                    Stock4Services, Inc. does not warrant that the supply of information will be
                                    uninterrupted,
                                    error-free, or not terminated altogether, and will not be liable for any direct,
                                    indirect, or
                                    consequential loss arising from any use of or reliance on the information and its
                                    availability.
                                </p>
                                <p>
                                    The information does not constitute or form part of an offer, subscription,
                                    recommendation, or
                                    solicitation to buy or sell any securities, commodities, financial objects, futures,
                                    options,
                                    stocks, or other investment vehicles, in any amount or at all or to take up any
                                    services, nor should
                                    it be relied on in connection with any contract or commitment whatsoever. YOU
                                    ABSOLUTELY MUST MAKE
                                    YOUR OWN DECISION BEFORE ACTING ON ANY INFORMATION OBTAINED FROM THIS WEBSITE. PAST
                                    PERFORMANCE IS
                                    NOT NECESSARY AN INDICATION OF FUTURE PERFORMANCE. The Emerging Company data
                                    available through this
                                    website does not constitute a recommendation or a solicitation by Stock4Services,
                                    Inc., nor is it a
                                    recommendation or solicitation that any particular investor should purchase or sell
                                    any particular
                                    securities, commodities, financial objects, futures, options, stocks or other
                                    investment vehicles,
                                    in any amount or at all. Stock4Services, Inc., and its employees and/or
                                    representatives are not and
                                    do not represent themselves as being investment advisors or investment managers, and
                                    do not make
                                    representations relating to the use of the information on this website.
                                    Stock4Services, Inc., does
                                    not warrant or make any representations regarding the use or the results of the use
                                    of the
                                    information and materials on this website in terms of their correctness, accuracy,
                                    reliability,
                                    profitability, or otherwise. The information and commentaries are not meant to be
                                    endorsements or
                                    offerings of any securities, commodities, financial objects, futures, options,
                                    stocks or other
                                    investment vehicles. In addition, they are not meant to be guides to investment.
                                    Stock4Services,
                                    Inc., its employees and representatives are in no way liable for the use of the
                                    information by
                                    others in investing or trading in investment vehicles.
                                </p>
                                <div className="terms_article__to_top"><a className="terms_article__to_top_link"
                                                                          href="#top">back to top</a>
                                </div>
                            </div>
                        </article>
                        <article className="page_article terms_article" id="no_warranty">
                            <h2 className="page_article__subheader"><span className="page_article__subheader_text">Stock4Services, Inc. Makes No Warranty</span>
                            </h2>
                            <div className="page_article__text">
                                <p>
                                    STOCK4SERVICES, INC., PROVIDES THE STOCK4SERVICES WEBSITES "AS IS," "WITH ALL
                                    FAULTS" AND "AS
                                    AVAILABLE," AND THE ENTIRE RISK AS TO SATISFACTORY QUALITY, PERFORMANCE, ACCURACY,
                                    AND EFFORT IS
                                    WITH YOU. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE STOCK4SERVICES,
                                    INC. PARTIES MAKE
                                    NO REPRESENTATIONS, WARRANTIES OR CONDITIONS, EXPRESS OR IMPLIED. THE
                                    STOCK4SERVICES, INC., PARTIES
                                    DISCLAIM ANY AND ALL WARRANTIES OR CONDITIONS, EXPRESS, STATUTORY AND IMPLIED,
                                    INCLUDING WITHOUT
                                    LIMITATION (1) WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                                    PURPOSE,
                                    WORKMANLIKE EFFORT, ACCURACY, TITLE, QUIET ENJOYMENT, NO ENCUMBRANCES, NO LIENS AND
                                    NON-INFRINGEMENT, (2) WARRANTIES OR CONDITIONS ARISING THROUGH COURSE OF DEALING OR
                                    USAGE OF TRADE,
                                    AND (3) WARRANTIES OR CONDITIONS THAT ACCESS TO OR USE OF THE STOCK4SERVICES
                                    WEBSITES WILL BE
                                    UNINTERRUPTED OR ERROR-FREE. THERE ARE NO WARRANTIES THAT EXTEND BEYOND THE FACE OF
                                    THIS AGREEMENT.
                                </p>
                                <div className="terms_article__to_top"><a className="terms_article__to_top_link"
                                                                          href="#top">back to top</a>
                                </div>
                            </div>
                        </article>
                        <article className="page_article terms_article" id="liability_limitation">
                            <h2 className="page_article__subheader"><span className="page_article__subheader_text">Liability Limitation; Your Exclusive Remedy</span>
                            </h2>
                            <div className="page_article__text">
                                <p>
                                    IN NO EVENT WILL ANY STOCK4SERVICES, INC., PARTY BE LIABLE FOR ANY DAMAGES,
                                    INCLUDING WITHOUT
                                    LIMITATION ANY INDIRECT, CONSEQUENTIAL, SPECIAL, INCIDENTAL, OR PUNITIVE DAMAGES
                                    ARISING OUT OF,
                                    BASED ON, OR RESULTING FROM THIS AGREEMENT OR YOUR USE OF THE STOCK4SERVICES
                                    WEBSITES, EVEN IF SUCH
                                    STOCK4SERVICES, INC. PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                                    THESE LIMITATIONS
                                    AND EXCLUSIONS APPLY WITHOUT REGARD TO WHETHER THE DAMAGES ARISE FROM (1) BREACH OF
                                    CONTRACT, (2)
                                    BREACH OF WARRANTY, (3) NEGLIGENCE, OR (4) ANY OTHER CAUSE OF ACTION, TO THE EXTENT
                                    SUCH EXCLUSION
                                    AND LIMITATIONS ARE NOT PROHIBITED BY APPLICABLE LAW. IF YOU ARE DISSATISFIED WITH
                                    THE
                                    STOCK4SERVICES WEBSITES, YOU DO NOT AGREE WITH ANY PART OF THIS AGREEMENT, OR YOU
                                    HAVE ANY OTHER
                                    DISPUTE OR CLAIM WITH OR AGAINST ANY STOCK4SERVICES, INC., PARTY WITH RESPECT TO
                                    THIS AGREEMENT OR
                                    THE STOCK4SERVICES WEBSITES, THEN YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE
                                    USING THE
                                    STOCK4SERVICES WEBSITES.
                                </p>
                                <p>
                                    Stock4Services, Inc., DBA Stock4Services, also referred to as Us, We,
                                    Stock4Services.com. You and
                                    Your, refers to The Subscriber to Stock4Services.com service(s).
                                </p>
                                <p>
                                    Stock4Services, Inc., DBA Stock4Services, and its Principals and Employees, ARE NOT
                                    registered as
                                    investment advisers under the Investment Advisers Act of 1940. Stock4Services, Inc.,
                                    DBA
                                    Stock4Services, and its Principals and Employees, offer free, direct access to
                                    business plans posted
                                    on the website by emerging companies seeking services in exchange for an equity
                                    position in the
                                    their company. All information and services originating from Stock4Services, Inc.,
                                    DBA
                                    Stock4Services, is for informational purposes only. Individual trading advice is not
                                    given at any
                                    time. Stock4Services' services, statements, information, advertising, promotions,
                                    educational
                                    information, any and all website information, fall under the First Amendment Right
                                    to Freedom of
                                    Speech. Stock4Services, Inc., DBA Stock4Services, its Principals, and Employees, are
                                    not Financial
                                    Advisors nor Broker Dealers nor Investment Advisors. Stock4Services relies upon the
                                    "publisher's
                                    exclusion" from the definition of investment adviser under Section 202(a)(11) of the
                                    Investment
                                    Advisers Act of 1940 and corresponding state securities laws. Therefore,
                                    Stock4Services does not
                                    offer or provide personalized advice. We facilitate the exchange of information
                                    about emerging
                                    companies in which our Subscribers may have interest. The information that we
                                    provide either by
                                    email, Internet, or from our website, is not intended to be, and should not be
                                    construed in any
                                    manner whatsoever as personalized advice. Also, information provided in emails or
                                    website, is not to
                                    be construed by any Subscriber or prospective Subscriber as solicitation to effect,
                                    or attempt to
                                    effect, any transaction in a security. Investments in the securities markets, and
                                    especially in
                                    options are extremely speculative and involve substantial risk. We encourage
                                    Subscribers to obtain
                                    personal advice from your professional investment advisor and to make independent
                                    investigations
                                    before acting on the information that is attained from any of our services.
                                </p>
                                <p>
                                    Stock4Services, Inc., DBA Stock4Services, has no affiliation, or financial agreement
                                    with any
                                    Brokerage Company.
                                </p>
                                <p>
                                    You, The Subscriber, are responsible for evaluating any and all emerging company
                                    report information
                                    provided by Stock4Services. Such information includes business plans, executive
                                    summaries, and
                                    objectives. You understand that there is a high degree of risk involved in selecting
                                    companies you
                                    intend to partner with. Stock4Services, its employees, staff, or editor, assume no
                                    responsibility or
                                    liability for your selection and partnering results. Any indicators, strategies,
                                    commentaries,
                                    and/or all other features are for informational and educational purposes only, and
                                    should not be
                                    construed as investment or trading advice.
                                </p>
                                <p>
                                    Stock4Services, Inc., DBA Stock4Services, does not in any way warrant or guarantee
                                    the success of
                                    any action that you take in reliance on our statements. Stock4Services, Inc., DBA
                                    Stock4Services,
                                    liability, whether in contract, tort, negligence, or otherwise, shall be limited in
                                    the aggregate to
                                    direct and actual damages not to exceed the fees received by or from the subscriber.
                                    I, the
                                    undersigned, agree that Stock4Services, Inc., DBA Stock4Services, and all and any
                                    employees of
                                    Stock4Services, Inc., DBA Stock4Services, will not and cannot be liable for
                                    consequential,
                                    incidental, punitive, special, exemplary or indirect damages resulting directly or
                                    indirectly from
                                    the use of or reliance upon any material provided by Stock4Services, Inc., DBA
                                    Stock4Services and
                                    all and any employees of Stock4Services, Inc., DBA Stock4Services. Without
                                    limitation,
                                    Stock4Services, Inc., DBA Stock4Services, and all and any employees of
                                    Stock4Services, Inc., DBA
                                    Stock4Services, will not and cannot and shall not be responsible or liable for any
                                    loss or damages
                                    related to, either directly or indirectly, (1) any decline in market value or loss
                                    of any
                                    investment; (2) a subscriber's inability to use or any delay in accessing
                                    Stock4Services, Inc., DBA
                                    Stock4Services website or any other source of material provided by Stock4Services,
                                    Inc., DBA
                                    Stock4Services, including, but not limited to email; (3) any absence of material on
                                    Stock4Services,
                                    Inc., DBA Stock4Services website or email; (4) Stock4Services, Inc., DBA
                                    Stock4Services failure to
                                    deliver or delay in delivering any material or (5) any kind of error in transmission
                                    of material.
                                    Stock4Services, Inc., DBA Stock4Services and Subscriber acknowledge that, without
                                    limitation, the
                                    above-enumerated conditions cannot be the probable result of any breach of any
                                    agreement between
                                    Stock4Services, Inc., DBA Stock4Services and Subscriber.
                                </p>
                                <div className="terms_article__to_top"><a className="terms_article__to_top_link"
                                                                          href="#top">back to top</a>
                                </div>
                            </div>
                        </article>
                        <article className="page_article terms_article" id="acknowledgement_and_agreement">
                            <h2 className="page_article__subheader"><span className="page_article__subheader_text">ACKNOWLEDGEMENT AND AGREEMENT</span>
                            </h2>
                            <div className="page_article__text">
                                <p>
                                    Notwithstanding any other agreement or other communications between Stock4Services,
                                    Inc., DBA
                                    Stock4Services and Subscriber to the contrary, receipt or use of any material
                                    provided by
                                    Stock4Services, Inc., DBA Stock4Services, at any time and through any means, whether
                                    directly or
                                    indirectly, represents acknowledgement by such person of this disclaimer and
                                    agreement with its
                                    terms and conditions. Stock4Services, Inc., DBA Stock4Services makes no
                                    representations or
                                    warranties about the accuracy or completeness of the information contained on this
                                    website,
                                    services, alerts, reports, or in emails.
                                </p>
                                <div className="terms_article__to_top"><a className="terms_article__to_top_link"
                                                                          href="#top">back to top</a>
                                </div>
                            </div>
                        </article>
                        <article className="page_article terms_article" id="changes_to_the_websites">
                            <h2 className="page_article__subheader"><span className="page_article__subheader_text">Changes to the Stock4Services Websites; Additional Liability Limitation</span>
                            </h2>
                            <div className="page_article__text">
                                <p>
                                    THE STOCK4SERVICES, INC. PARTIES MAY CHANGE THE STOCK4SERVICES WEBSITES OR DELETE
                                    FEATURES IN ANY
                                    WAY, AT ANY TIME, AND FOR ANY REASON. As you use the STOCK4SERVICES websites, you
                                    should expect to
                                    receive, access or use information, materials, graphics, software, data and content
                                    (collectively,
                                    "Content") originated by Stock4Services, Inc. and persons other than Stock4Services,
                                    Inc. (any such
                                    person is referred to as a "Third Party"). WITHOUT LIMITING THE GENERALITY OF
                                    SECTIONS 9 AND 10, YOU
                                    ACKNOWLEDGE AND AGREE THAT THE STOCK4SERVICES, INC. PARTIES ARE NOT RESPONSIBLE OR
                                    LIABLE FOR (1)
                                    ANY CONTENT, INCLUDING WITHOUT LIMITATION, ANY INFRINGING, INACCURATE, OBSCENE,
                                    INDECENT,
                                    THREATENING, OFFENSIVE, DEFAMATORY, TORTIOUS, OR ILLEGAL CONTENT, OR (2) ANY THIRD
                                    PARTY CONDUCT,
                                    TRANSMISSIONS OR DATA. IN ADDITION, WITHOUT LIMITING THE GENERALITY OF SECTIONS 9
                                    AND 10, YOU
                                    ACKNOWLEDGE AND AGREE THAT STOCK4SERVICES, INC. IS NOT RESPONSIBLE OR LIABLE FOR (1)
                                    ANY VIRUSES OR
                                    OTHER DISABLING FEATURES THAT AFFECT YOUR ACCESS TO OR USE OF THE STOCK4SERVICES
                                    WEBSITES, (2) ANY
                                    INCOMPATIBILITY BETWEEN THE STOCK4SERVICES WEBSITES AND OTHER WEBSITES, SERVICES,
                                    SOFTWARE AND
                                    HARDWARE, (3) ANY DELAYS OR FAILURES YOU MAY EXPERIENCE IN INITIATING, CONDUCTING OR
                                    COMPLETING ANY
                                    TRANSMISSIONS OR TRANSACTIONS IN CONNECTION WITH THE STOCK4SERVICES WEBSITES IN AN
                                    ACCURATE OR
                                    TIMELY MANNER, OR (4) ANY DAMAGES OR COSTS OF ANY TYPE ARISING OUT OF OR IN ANY WAY
                                    CONNECTED WITH
                                    YOUR USE OF ANY SERVICES AVAILABLE FROM THIRD PARTIES THOUGH LINKS CONTAINED ON THE
                                    STOCK4SERVICES
                                    WEBSITES. THE LIMITATIONS, EXCLUSIONS AND DISCLAIMERS IN SECTIONS 9, 10 AND 11 OF
                                    THIS AGREEMENT
                                    APPLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, AND ARE NOT INTENDED TO
                                    DEPRIVE YOU OF ANY
                                    MANDATORY PROTECTIONS PROVIDED TO YOU UNDER APPLICABLE LAW.
                                </p>
                                <div className="terms_article__to_top"><a className="terms_article__to_top_link"
                                                                          href="#top">back to top</a>
                                </div>
                            </div>
                        </article>
                        <article className="page_article terms_article" id="service_fees">
                            <h2 className="page_article__subheader"><span className="page_article__subheader_text">Service Fees and Penalties</span>
                            </h2>
                            <div className="page_article__text">
                                <p>
                                    Registering and searching Stock4Services emerging company information database is
                                    free.
                                    Stock4Services, Inc., DBA Stock4Services will only receive compensation when the
                                    subscriber executes
                                    a stock transaction with an emerging company. Stock4Services commission is 15% of
                                    the stock the
                                    subscriber receives in the transaction. This transfer must occur within seven
                                    business days of the
                                    transaction. The subscriber agrees to pay Stock4Services, Inc. the sum of $750,000
                                    per infraction
                                    for failure to transfer the stock, and does not void its obligation to transfer
                                    stock.
                                </p>
                                <div className="terms_article__to_top"><a className="terms_article__to_top_link"
                                                                          href="#top">back to top</a>
                                </div>
                            </div>
                        </article>
                        <article className="page_article terms_article" id="choice_of_legal_forum_selection">
                            <h2 className="page_article__subheader"><span className="page_article__subheader_text">Choice of Legal Forum Selection</span>
                            </h2>
                            <div className="page_article__text">
                                <p>
                                    You agree that any dispute arising out of or relating to these Terms or any content
                                    posted to a
                                    site, including copies and republication thereof, whether based in contract, tort,
                                    statutory or
                                    other law, will be governed by the laws of the State of Delaware, excluding its
                                    conflicts of law
                                    provisions. You further consent to the personal jurisdiction of an exclusive venue
                                    in the federal
                                    and state courts located in and serving Delaware as the legal forum for any such
                                    dispute.
                                </p>
                                <div className="terms_article__to_top"><a className="terms_article__to_top_link"
                                                                          href="#top">back to top</a>
                                </div>
                            </div>
                        </article>
                    </div>

                </Box>
                <Footer/>
            </Container>
        </ThemeProvider>
    )
}