import * as React from 'react';
import Box from '@mui/material/Box';
import arrow from '../img/arrow.png'
import Slider from "react-slick";
import services1 from '../img/services/services1.svg';
import services2 from '../img/services/services2.svg';
import services3 from '../img/services/services3.svg';
import services4 from '../img/services/services4.svg';
import services5 from '../img/services/services5.svg';
import services6 from '../img/services/services6.svg';

export default function Services(){
    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 1,
        variableWidth: true,
        arrows:false,
        centerPadding: '10px',
        responsive: [
            {
                breakpoint: 900,
                settings: {
                    centerMode: true,
                    centerPadding: '40px',
                    variableWidth: false,
                    autoplay: true,
                }
            }
        ]
    };
    return(
        <Box className={'services__box'} id={'services'}>
            <Box className={'services__content'}>
                <Box className={'services__text'}>
                    <h2>Bootstrap the growth of vetted scale-ups
                    </h2>
                    <p>Companies that can benefit from <strong>Stock4Services</strong> the most include:
                    </p>
                    <img src={arrow} alt=""/>
                </Box>
                <Slider {...settings}>
                    <Box className={'services__slide'}>
                        <img src={services3} alt=""/>
                        <p>Broadcast and Online networks </p>
                    </Box>
                    <Box className={'services__slide'}>
                        <img src={services4} alt=""/>
                        <p>Event producer and Publishers  </p>
                    </Box>
                    <Box className={'services__slide'}>
                        <img src={services6} alt=""/>
                        <p>IT solutions providers </p>
                    </Box>
                    <Box className={'services__slide'}>
                        <img src={services1} alt=""/>
                        <p>Law firms</p>
                    </Box>
                    <Box className={'services__slide'}>
                        <img src={services2} alt=""/>
                        <p>PR agencies</p>
                    </Box>
                    <Box className={'services__slide'}>
                        <img src={services5} alt=""/>
                        <p>Media agencies  </p>
                    </Box>

                </Slider>
            </Box>
        </Box>
    )
};