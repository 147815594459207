import '../App.scss';
import '@csstools/normalize.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as React from "react";
import { useState } from "react";
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider  } from '@mui/material/styles';
import Header from "../components/header";
import Banner from "../components/banner";
import SliderLogo from "../components/sliderLogo";
import Why from "../components/why";
import Benefits from "../components/benefits";
import How from '../components/how';
import Services from "../components/services";
import Form from "../components/form";
import Footer from "../components/footer";
import Button from "@mui/material/Button";


const scrollToDiv = id =>{
    const divElement = document.getElementById(id);
    divElement.scrollIntoView({ behavior: 'smooth' });
}

const hash = window.location.search;


const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 901,
            lg: 1113,
            xl: 1414,
        },
    },
});
function Home() {
    const [visible, setVisible] = useState(false);
    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        const form = document.getElementById('services');
        const slider = document.getElementById('sliderLogo');
        const how = document.getElementById('how');
        if(form){
            let height = form.offsetHeight;
            let offsetTopEnd = how.offsetTop + height;
            let offsetTop = slider.offsetTop;
            if (scrolled > offsetTop && scrolled < offsetTopEnd ){
                setVisible(true)
            }
            else{
                setVisible(false)
            }
        }
    };
    const scroll = ()=>{
        if (hash === '?how'){
            scrollToDiv('how')
        }
        else if(hash === '?why'){
            scrollToDiv('why')
        }
        else if(hash === '?form'){
            scrollToDiv('form')
        }
    }

    window.addEventListener('scroll', toggleVisible);
    window.addEventListener('load', scroll);
  return (
      <ThemeProvider theme={theme}>
          <Container maxWidth="xl" className={'container'}>
              <Header/>
          </Container>
              <Banner/>
          <Container maxWidth="xl" className={'container'}>
              <SliderLogo/>
              <Why/>
          </Container>
              <Benefits/>
          <Container maxWidth="xl" className={'container'}>
              <How/>
          </Container>
          <Services/>
          <Form/>
          <Footer/>
          <Button style={{display: visible ? 'inline' : 'none'}} className={"btn fixed"} onClick={() => scrollToDiv('form')} variant="contained">get started!</Button>

      </ThemeProvider>
  );
}

export default Home;
