import * as React from "react";
import { useState} from 'react';
import '../App.scss';
import '@csstools/normalize.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { createTheme, ThemeProvider  } from '@mui/material/styles';
import Header from "../components/header";
import Footer from "../components/footer";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import phone from '../img/smartphones.svg';
import email from '../img/email.svg';
import List from '@mui/material/List';
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import {useForm} from "react-hook-form";
import Input from '@mui/material/Input';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import simpleFetch from "very-simple-fetch";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import Container from '@mui/material/Container';
import {
    GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";


const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 901,
            lg: 1113,
            xl: 1414,
        },
    },
});


const Contact = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [value, setValue] = useState();
    const key = global.config.recaptcha_site_key;
    const onblur = () =>{
        document.getElementById('valid').style.display= 'block';
    }
    const onSubmit = (data) => {
        const response = simpleFetch({
            url:global.config.webhook_contact,
            method: 'POST',
            mode: 'no-cors',
            body: JSON.stringify(data),

        })
        if(response){
            console.log(response)
        }
    };
    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="xl" className={'container'}>
                <Header/>
            </Container>
                <Box className={'contact__box'}>
                    <Grid className={'contact__content'} container direction="row" justifyContent="space-between" alignItems="center">
                        <Grid item xl={6} lg={6} md={6} sm={12}>
                            <h2>Contact Us</h2>
                            <Box className={'contact__help-box'} sx={{alignItems: 'flex-end'}}>
                                <h3>How we can help?
                                </h3>
                                <p>Please, contact us with any questions you may have about how Stock4Services can help your company.
                                </p>
                                <List className={'contact__list'} sx={{ width: '100%', background: 'none' }}>
                                    <ListItem alignItems="flex-start" sx={{padding: '15px', marginBottom: '30px', display: 'none'}}>
                                        <ListItemAvatar sx={{marginTop: 0}} className={'contact__help-avatar'}>
                                            <Avatar alt="" src={phone} />
                                        </ListItemAvatar>
                                        <ListItemText className={'contact__help-text'} sx={{margin: 0}}
                                          primary={'Phone Number'}
                                          secondary={
                                              <React.Fragment>
                                                <span className={'contact__help-typografy'} >
                                                    +1 253 12345628759

                                                </span>
                                              </React.Fragment>
                                          }
                                        />
                                    </ListItem>
                                    <ListItem alignItems="flex-start" sx={{padding: '15px', marginBottom: '30px'}}>
                                        <ListItemAvatar sx={{marginTop: 0}} className={'contact__help-avatar'}>
                                            <Avatar alt="" src={email} />
                                        </ListItemAvatar>
                                        <ListItemText sx={{margin: 0}} className={'contact__help-text'}
                                          primary={'E-mail'}
                                          secondary={
                                              <React.Fragment>
                                                <a className={'contact__help-typografy'} href="mailto:info@stock4services.com">
                                                    info@stock4services.com

                                                </a>
                                              </React.Fragment>
                                          }
                                        />
                                    </ListItem>
                                </List>

                            </Box>
                        </Grid>
                        <Grid item xl={6} lg={5} md={5} sm={12}>
                            <GoogleReCaptchaProvider reCaptchaKey={key}>
                                <Box component="form"  className={'form__contact'} onSubmit={handleSubmit(onSubmit)} noValidate autoComlete="off">
                                    <h3>get in Touch</h3>
                                    <Box sx={{position: 'relative'}}>
                                        <Input className={'input-text'}
                                               {...register('fullName',{required: true, pattern: /^\S+ \S+$/i})}
                                               placeholder={'Name and Last Name'}
                                               onBlur={e => e.target.value = e.target.value.trim()} />
                                        <p className={'error'}>{errors.fullName?.type === 'required' && "Name and Last Name is required"}</p>
                                        <p className={'error'}>{errors.fullName?.type === 'pattern' && "Name and Last Name is not valid"}</p>
                                    </Box>
                                    <Box sx={{position: 'relative'}}>
                                        <PhoneInput
                                            placeholder={'Phone Number'}
                                            className={'phone__box'}
                                            {...register('phone', {required: true})}
                                            international
                                            defaultCountry="US"
                                            value={value}
                                            onBlur={onblur}
                                            onChange={setValue}/>

                                        <p className={'error'}>{errors.phone?.type === 'required' && "Phone Number is required"}</p>
                                        <p id={'valid'} className={'error'}>{value  && isValidPhoneNumber(value) ? '' : 'Phone Number is not valid'}</p>
                                    </Box>
                                    <Box sx={{position: 'relative'}}>
                                        <Input className={'input-text'} {...register('email',{required: true, pattern: /^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{1,20}$/})} placeholder={'E-mail'} />
                                        <p className={'error'}>{errors.email?.type === 'required' && "Email is required"}</p>
                                        <p className={'error'}>{errors.email?.type === 'pattern'&& "Email is not valid"}</p>
                                    </Box>
                                    <TextField
                                        id="outlined-multiline-static"
                                        className={'textarea'}
                                        {...register('message')}
                                        label=""
                                        multiline
                                        rows={4}
                                        placeholder={'Your Message'}
                                    />

                                    <Box className={'bottom'}>

                                        <Button
                                            variant="contained"
                                            type="submit"
                                            onClick={ handleSubmit(onSubmit)}
                                            sx={{ width: '137px'}}
                                            className={'btn'}
                                        >Submit</Button>
                                    </Box>
                                </Box>
                            </GoogleReCaptchaProvider>
                        </Grid>
                    </Grid>
                </Box>
                <Footer/>
        </ThemeProvider>
    );
};

export default Contact;