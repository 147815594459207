import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import why_bg from '../img/why_bg2.jpg';
const scrollToDiv = id =>{
    const divElement = document.getElementById(id);
    divElement.scrollIntoView({ behavior: 'smooth' });
}

export default function Why(){
    return(
        <Box className={'why__box'} id="why">
            <Box className={"why__text"}>
                <h2>Why<br/> Stock<span>4</span>Services?</h2>
                <p>Turn your spare capacity into valuable stock in highly vetted disruptive companies
                    and co-invest alongside industry leaders. Join the revolutionary new way that stocks are
                    traded and services delivered. </p>
            </Box>
            <img src={why_bg} alt=""/>
            <Box className={'why__img'}>&nbsp;</Box>
            <Button className={"btn"} onClick={() => scrollToDiv('form')} variant="contained">get started!</Button>

        </Box>
    )
};