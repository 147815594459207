import * as React from "react";
import '../App.scss';
import '@csstools/normalize.css';
import Header from "../components/header";
import Footer from "../components/footer";
import Container from '@mui/material/Container';
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider  } from '@mui/material/styles';

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1113,
            xl: 1414,
        },
    },
});
export default function Aboutus(){
    return(
        <ThemeProvider theme={theme}>
            <Container maxWidth="xl" className={'static-page'}>
                <Header/>
                <Box className="page_article aboutus_article" maxWidth="lg">

                        <h2 className="page_article__header"><span className="page_article__header_text">Do you invest MONEY in the stock market?</span>
                        </h2>
                        <h3 className="page_article__header"><span className="page_article__header_text">There’s a better way!</span>
                        </h3>
                        <div className="page_article__text">
                            <p>
                                <span className="s4s">Stock4Services</span> was inspired by the phenomenal success
                                stories that prompted
                                a revolution in the way stock is traded.
                            </p>
                            <div>
                                <ul className="aboutus_page__list">
                                    <li className="aboutus_page__list_item">
                                        In 2004, rapper 50 Cent accepted Glaceau stock for endorsing its Vitaminwater
                                        brand. In
                                        2008, he earned in <a
                                        href="http://www.forbes.com/2007/09/18/igetmoney-remix-50cent-biz-media-cz_lg_0918bizigetmoney.html"
                                        target="_blank" rel={'noreferrer'}>over $100 million</a> when Coca-Cola purchased the company.

                                    </li>
                                    <li>
                                        In 2005, a graffiti artist accepted stock in lieu of payment for painting the
                                        walls of
                                        Facebook’s new offices.&nbsp;In 2013, that stock was worth <a
                                        href="http://bits.blogs.nytimes.com/2012/02/07/facebook-graffiti-artist-could-be-worth-500-million/"
                                        target="_blank" rel={'noreferrer'}>$500 million</a>.
                                    </li>
                                </ul>
                            </div>
                            <div className="aboutus_article__video">
                                <iframe width="450" height="285" title={'video'}
                                        src="//www.youtube.com/embed/1p9wT9OyrQ4?rel=0&amp;showinfo=0" frameBorder="0"
                                        allowFullScreen=""></iframe>
                            </div>
                            <p>
                                We saw how anyone with the means to help a company progress could benefit from investing
                                in emerging
                                companies with high growth potential. Conversely, we saw how a growing business could
                                progress
                                beyond the limitations of its budget by offering stock in lieu of cash. What we didn’t
                                see was an
                                easily navigated professional network where the two could connect.

                            </p>
                            <p>
                                So we created <span className="s4s">Stock4Services</span> as a launching pad to
                                facilitate such deals:
                                from investing ad space to crowdsourcing services such as public relations, social media
                                marketing,
                                information technology, and others. We figured that if a graffiti painter could make
                                half a billion
                                dollars for $60,000 worth of services, there was no reason why other service providers
                                couldn’t earn
                                millions employing the same tactics.

                            </p>
                            <p>
                                We source promising companies through a careful selection process that considers the
                                strength of
                                management, growing revenues, and respected investors already on board with the company.
                                Service
                                providers, broadcasters, publishers and celebrities can then select from our curated
                                pool of
                                companies, safe in the knowledge that they are likely to deliver high returns.

                            </p>
                            <p>
                                There are currently no listing fees or other charges for the service providers for
                                using <span className="s4s">Stock4Services</span> platform to find suitable investment
                                opportunities.
                            </p>
                        </div>
                    <div className="aboutus_page__benefits__tiles">
                        <h2 className="aboutus_page__benefits__tiles_header">Companies that can benefit from
                            Stock4Services the most
                            include:</h2>
                        <ul>
                            <li>Publishers, cable and broadcast TV, online networks which regularly have remnant
                                advertising
                                inventory</li>
                            <li> PR agencies</li>
                            <li>
                                Law firms
                            </li>
                            <li>SaaS companies</li>
                            <li>IT solution providers</li>
                        </ul>
                    </div>
                    <div className="aboutus_page__howitworks">
                        <h2 className="aboutus_page__howitworks_header">Here’s how it works:<span
                            className="aboutus_page__howitworks_header_line"></span></h2>
                        <ol>
                            <li> You tell us what services or assets you may consider for investing (examples:
                                “Legal
                                services, 2,000 hours” or “Advertising, 4 pages.”) and the applicable pricing.</li>
                            <li>We search for the best opportunities for you and present our recommendations,
                                acting on a
                                commission-only basis.</li>
                            <li>
                                You make the decision. You earn the profit. You grow your portfolio.
                            </li>
                        </ol>

                    </div>
                </Box>
                <Footer/>
            </Container>
        </ThemeProvider>
        )
}