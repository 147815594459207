import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from "react-slick";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <Box
            className={className}
            style={{ ...style, display: "block", background: "white" }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <Box
            className={className}
            style={{ ...style, display: "block", background: "white" }}
            onClick={onClick}
        />
    );
}

export default function SliderLogo() {

        const settings = {
            dots: false,
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            variableWidth: true,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1100,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        variableWidth: false,

                    }
                }
            ],
            nextArrow: <SampleNextArrow/>,
            prevArrow: <SamplePrevArrow/>
        };
        return (
            <Box className={'sliderLogo__box'} id={'sliderLogo'}>
                <h2 className={"title__sliderForm"}>
                    Scale-ups in high-growth industries use <strong>S4S</strong> to increases their odds of success
                </h2>
                <Slider {...settings}>
                    <a href={'https://unicoin.com/'} className={'slide slide1'} target="_blank" rel={'noreferrer'}>&nbsp;</a>
                    <a href={'https://unicornhunters.com/'} className={'slide slide2'} target="_blank" rel={'noreferrer'}>&nbsp;</a>
                    <a href={'https://club.unicornhunters.com/'} className={'slide slide3'} target="_blank" rel={'noreferrer'}>&nbsp;</a>
                    <a href={'https://transparentbusiness.com/'} className={'slide slide4'} target="_blank" rel={'noreferrer'}>&nbsp;</a>
                    <a href={'https://services4stock.com/'} className={'slide slide5'} target="_blank" rel={'noreferrer'}>&nbsp;</a>
                </Slider>
            </Box>
        )

};