import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import benefits_bg from '../img/benefits_bg.jpg';
import benefits1 from '../img/benefits/benefits1.png';
import benefits2 from '../img/benefits/benefits2.png';
import benefits3 from '../img/benefits/benefits3.png';
import benefits4 from '../img/benefits/benefits4.png';
import arrow from '../img/arrow.png';

export default function Benefits(){
    return(
        <Box className={'benefits__box'}>
            <h2><span>Benefits of </span>having your company on Stock4Services</h2>
            <Box className={"benefits__content"}>
                <img src={benefits_bg} alt=""/>
                <Box className={"benefits__text"}>
                    <List sx={{ width: '100%', maxWidth: 600, background: 'none' }}>
                        <ListItem alignItems="flex-start" sx={{padding: 0, marginBottom: '40px'}}>
                            <ListItemAvatar className={'benefits__text-avatar'}>
                                <Avatar alt="" src={benefits1} />
                            </ListItemAvatar>
                            <ListItemText sx={{margin: 0}}
                                secondary={
                                    <React.Fragment>
                                        <span className={'benefits__text-typografy'} >
                                            Safely transform spare capacity into equity in highly vetted companies
                                            with a compelling exit strategy to go public.

                                        </span>
                                    </React.Fragment>
                                }
                            />
                        </ListItem>

                        <ListItem alignItems="flex-start" sx={{padding: 0, marginBottom: '40px'}}>
                            <ListItemAvatar className={'benefits__text-avatar'}>
                                <Avatar alt="" src={benefits2} />
                            </ListItemAvatar>
                            <ListItemText sx={{margin: 0}}
                                secondary={
                                    <React.Fragment>
                                        <span className={'benefits__text-typografy'} >
                                            Turn your unsold time, space, and inventory into value. Convert idle
                                            resources into real benefits for your business.

                                        </span>

                                    </React.Fragment>
                                }
                            />
                        </ListItem>

                        <ListItem alignItems="flex-start" sx={{padding: 0, marginBottom: '40px'}}>
                            <ListItemAvatar className={'benefits__text-avatar'}>
                                <Avatar alt="" src={benefits3} />
                            </ListItemAvatar>
                            <ListItemText sx={{margin: 0}}
                                secondary={
                                    <React.Fragment>
                                        <span className={'benefits__text-typografy'} >
                                            Supporting scale-ups in high-growth industries increases the odds of
                                            success, which in return increases the value of the asset you hold.
                                        </span>

                                    </React.Fragment>
                                }
                            />
                        </ListItem>

                        <ListItem alignItems="flex-start" sx={{padding: 0, marginBottom: '40px'}}>
                            <ListItemAvatar className={'benefits__text-avatar'}>
                                <Avatar alt="" src={benefits4} />
                            </ListItemAvatar>
                            <ListItemText sx={{margin: 0}}
                                secondary={
                                    <React.Fragment>
                                        <span className="benefits__text-typografy" style={{maxWidth:'285px'}}>
                                            Maximize your profit by making use of potential services already going to waste.
                                        </span>

                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                    </List>
                    <p className={'benefits__text-bottom'}>
                        Unique pre-IPO <span>opportunities curated by</span> the <strong>Circle of Money</strong> from <span>the <strong>Unicorn Hunters</strong> show.</span>
                        <a href="https://unicornhunters.com"><img src={arrow} alt=""/></a>

                    </p>
                </Box>
            </Box>
        </Box>
    )
};