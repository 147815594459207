import * as React from 'react';
import { useState} from 'react';
import {Link} from "react-router-dom";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useForm } from "react-hook-form";
import {styled, useTheme} from "@mui/material/styles";
import Input from "@mui/material/Input";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Checkbox from '@mui/material/Checkbox';
import checked from '../img/checked.png';
import simpleFetch from 'very-simple-fetch';
import 'react-phone-number-input/style.css'
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input';
import {
    GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";


const steps = ['company information', 'Business information'];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const types = [
    "B2B",
    "B2C",
    "B2G",
    "B2C&B2B",

];
const industrys = [
    'Agriculture',
    'Basic Metal Production',
    'Chemical industries',
    'Commerce',
    'Construction',
    'Education',
    'Financial services',
    'Food and Beverage',
    'Health services',
    'Tourism',
    'Mining',
    'Mechanical and electrical engineering',
    'Media/ Communications',
    'Oil and gas production',
    'Postal and telecommunications services',
    'Public service',
    'Shipping',
    'Textiles',
    'Transport',
    'Others',


];

const areas = [
    'Ad Agency',
    'Business Development',
    'Broadcast Advertising',
    'Cable Advertising',
    'Consulting',
    'Design Marketing',
    'Digital Marketing',
    'Events',
    'Financial',
    'Interactive Production',
    'Legal',
    'Office Space',
    'Online Advertising',
    'Outdoor Advertising',
    'Print Advertising',
    'Public Relations',
    'Sales',
    'Social Media Marketing',
    'Others' ,

];
const abouts = [
    'Facebook Page',
    'LinkedIn',
    'Twitter',
    'Media',
    'Search Engine',
    'Referral',
    'Friend',
    'Digital Magazines',
    'Finance Blogs',
    'Others',


];
function getStyles(type, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(type) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,

    };
}

const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: 4,
    width: 22,
    height: 22,
    boxShadow:'none',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#d1d1d1',
    backgroundImage:'none',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ff9d00',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
}));
const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#ff9d00',
    backgroundImage: 'none',
    '&:before': {
        display: 'block',
        width: 22,
        height: 22,
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#ff9d00',
    },
});


export default function Form(){
    const key = global.config.recaptcha_site_key;
    const [activeStep, setActiveStep] = React.useState(0);
    const [setResponse] = useState(null);
    const [value, setValue] = useState();
    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };
    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };
    const { register, handleSubmit, formState: { errors },clearErrors } = useForm();
    const onblur = () =>{
        document.getElementById('valid').style.display= 'block';
    }
    const onSubmit = (data) => {
        console.log(data);
        handleNext();
    };
    const onSubmit_end = (data) => {
        const response = simpleFetch({
            url:global.config.webhook_main,
            method: 'POST',
            mode: 'no-cors',
            headers: new Headers({'Content-Type':'application/json'}),
            body: JSON.stringify(data),
        }).then(() => {
            handleNext();
        })
        .catch((error) => {
            console.log('error:');
            console.log(error);
        });

        setResponse(response)
    };


    const theme = useTheme();
    const [personName, setPersonName] = React.useState([]);
    const [personName2, setPersonName2] = React.useState([]);
    const [personName3, setPersonName3] = React.useState([]);
    const [personName4, setPersonName4] = React.useState([]);
    const handleChange = (event)=> {
        setPersonName(event.target.value);
        clearErrors("businessType")
    }
    const handleChange2 = (event) => {
        setPersonName2(event.target.value);
        clearErrors("businessIndustry")

    };
    const handleChange3 = (event) => {
        setPersonName3(event.target.value);
        clearErrors("businessArea")
    };
    const handleChange4 = (event) => {
        setPersonName4(event.target.value);
        clearErrors("aboutUs")
    };

    return(
        <Box className={'form__box'} id={'form'}>
            <h2 className={'title__sliderForm'}>
                Did you know that supporting scale-ups in high-growth industries <strong>increases the odds of success,
                which in return increases the value of the asset you hold?</strong>
            </h2>
            <Box className={'form__block-form'}>
                <h3>Fill out the form below and <strong>Join this new way that stocks are traded and services delivered.</strong>
                </h3>
                <GoogleReCaptchaProvider reCaptchaKey={key}>
                    <Box component="form"  className={'form__steps'} onSubmit={handleSubmit(onSubmit_end)} noValidate autoComlete="off">

                        <React.Fragment>
                            {activeStep === steps.length ? (
                                <React.Fragment>
                                    <Box className={'congrats'}>
                                        <img src={checked} alt=''/>
                                        <Typography variant="h3" gutterBottom>
                                            Congrats!
                                        </Typography>
                                        <Typography variant="h4">
                                            Your application as a Service Provider has been submitted.
                                        </Typography>
                                        <p>Soon your Personal Concierge will be in touch with you! Stay tuned for more details.</p>
                                    </Box>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <Stepper activeStep={activeStep} className={'steps-top'}>
                                        {steps.map((label) => (
                                            <Step key={label} className={'steps-name'}>
                                                <StepLabel>{label}</StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                    {activeStep === steps.length - 1 ? (
                                            <React.Fragment>
                                                <Box className={'company__box'}>
                                                    <Box sx={{position: 'relative'}}>
                                                        <FormControl className={'select-box'}>
                                                            <InputLabel >Business Area
                                                            </InputLabel>
                                                            <Select
                                                                value={personName3}
                                                                {...register('businessArea', {required: true})}
                                                                id="select3"
                                                                onChange={handleChange3}
                                                                className={'select'}
                                                                input={<OutlinedInput label="Business Area" />}
                                                                MenuProps={MenuProps}
                                                            >
                                                                {areas.map((type) => (
                                                                    <MenuItem
                                                                        key={type}
                                                                        value={type}
                                                                        style={getStyles(type, personName, theme)}
                                                                    >
                                                                        {type}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>

                                                        </FormControl>
                                                        <p className={'error'}>{errors.businessArea?.type === 'required' && "Business Area is required"}</p>
                                                    </Box>
                                                    <Box sx={{position: 'relative'}}>
                                                        <TextField
                                                            id="outlined-multiline-static"
                                                            className={'textarea'}
                                                            {...register('aboutYourCompany', {required: true})}
                                                            label=""
                                                            multiline
                                                            rows={4}
                                                            placeholder={'Tell us about your company services'}
                                                        />
                                                        <p className={'error'}>{errors.aboutYourCompany?.type === 'required' && "About your company is required"}</p>
                                                    </Box>
                                                    <Box sx={{position: 'relative'}}>
                                                        <FormControl className={'select-box'}>
                                                            <InputLabel >How did you hear about us?</InputLabel>
                                                            <Select
                                                                value={personName4}
                                                                {...register('aboutUs', {required: true})}
                                                                id="select5"
                                                                onChange={handleChange4}
                                                                className={'select'}
                                                                input={<OutlinedInput label="How did you hear about us?" />}
                                                                MenuProps={MenuProps}
                                                            >
                                                                {abouts.map((industry) => (
                                                                    <MenuItem
                                                                        key={industry}
                                                                        value={industry}
                                                                        style={getStyles(industry, personName4, theme)}
                                                                    >
                                                                        {industry}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>

                                                        </FormControl>
                                                        <p className={'error'}>{errors.aboutUs?.type === 'required' && "How did you hear about us? is required"}</p>
                                                    </Box>
                                                    <Box sx={{position: 'relative'}}>
                                                        <Box className={'checkbox'}>
                                                            <Checkbox
                                                                sx={{
                                                                    '&:hover': {bgcolor: 'transparent'},
                                                                    paddingLeft: 0
                                                                }}
                                                                disableRipple
                                                                color="default"
                                                                checkedIcon={<BpCheckedIcon/>}
                                                                icon={<BpIcon/>}
                                                                inputProps={{'aria-label': 'Checkbox demo'}}
                                                                {...register('termsAndConditions', {required: true})}
                                                            />
                                                            <InputLabel >I Agree with <Link to="/termsofuse" target="_blank" rel={'noreferrer'}>Terms and Conditions</Link> of the service</InputLabel>
                                                        </Box>
                                                        <p className={'error'}>{errors.termsAndConditions?.type === 'required' && "Terms and Conditions is required"}</p>
                                                    </Box>

                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                                                        <Button onClick={handleBack} variant="contained" sx={{ width: '137px'}}
                                                                className={'btn back'}>
                                                            Back
                                                        </Button>
                                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} className={'bottom'}>

                                                            <Button
                                                                variant="contained"
                                                                onClick={handleSubmit(onSubmit_end)}
                                                                sx={{ width: '137px'}}
                                                                className={'btn'}
                                                            >
                                                                submit
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </React.Fragment>

                                    ):(
                                        <React.Fragment>
                                            <Box className={'company__box'}>
                                                <Box sx={{position: 'relative'}}>
                                                    <Input className={'input-text'} {...register('firstName', {
                                                        required: true,
                                                        pattern: /^[A-Za-z\s]+$/i
                                                    })} placeholder={'First Name'}/>
                                                    <p className={'error'}>{errors.firstName?.type === 'required' && "First name is required"}</p>
                                                    <p className={'error'}>{errors.firstName?.type === 'pattern' && "First name is not valid"}</p>
                                                </Box>
                                                <Box sx={{position: 'relative'}}>
                                                    <Input className={'input-text'} {...register('lastName', {
                                                        required: true,
                                                        pattern: /^[A-Za-z\s]+$/i
                                                    })} placeholder={'Last Name'}/>
                                                    <p className={'error'}>{errors.lastName?.type === 'required' && "Last name is required"}</p>
                                                    <p className={'error'}>{errors.lastName?.type === 'pattern' && "Last name is not valid"}</p>
                                                </Box>
                                                <Box sx={{position: 'relative'}}>
                                                    <Input className={'input-text'} {...register('email', {
                                                        required: true,
                                                        pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{1,20}$/
                                                    })} placeholder={'Work E-mail'}/>
                                                    <p className={'error'}>{errors.email?.type === 'required' && "Email is required"}</p>
                                                    <p className={'error'}>{errors.email?.type === 'pattern' && "Email is not valid"}</p>
                                                </Box>
                                                <Box sx={{position: 'relative'}}>
                                                    <PhoneInput
                                                        placeholder={'Phone Number'}
                                                        className={'phone__box'}
                                                        {...register('phone', {required: true})}
                                                        international
                                                        defaultCountry="US"
                                                        value={value}
                                                        onBlur={onblur}
                                                        onChange={setValue}/>

                                                    <p className={'error'}>{errors.phone?.type === 'required' && "Phone Number is required"}</p>
                                                    <p id={'valid'} className={'error'}>{value  && isValidPhoneNumber(value) ? '' : 'Phone Number is not valid'}</p>
                                                </Box>
                                                <Box sx={{position: 'relative'}}>
                                                    <Input className={'input-text'} {...register('companyName', {required: true, pattern:/(?=^.{3,}$)(?![.\n])(?=.*[A-z]).*/})}
                                                           placeholder={'Company Name'}
                                                           onBlur={e => e.target.value = e.target.value.trim()}
                                                    />
                                                    <p className={'error'}>{errors.companyName?.type === 'required' && "Company Name is required"}</p>
                                                    <p className={'error'}>{errors.companyName?.type === 'pattern' && "Company Name is not valid"}</p>
                                                </Box>
                                                <Box sx={{position: 'relative'}}>
                                                    <Input className={'input-text'} {...register('companyWebsite',
                                                        {required: true,
                                                            pattern:/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
                                                        })}
                                                       placeholder={'Company Website'}/>
                                                    <p className={'error'}>{errors.companyWebsite?.type === 'required' && "Company Website is required"}</p>
                                                    <p className={'error'}>{errors.companyWebsite?.type === 'pattern' && "Company Website is not valid"}</p>
                                                </Box>
                                                <Box sx={{position: 'relative'}}>
                                                    <FormControl className={'select-box'}>
                                                        <InputLabel>Business Type</InputLabel>
                                                        <Select
                                                            value={personName}
                                                            {...register('businessType', {required: true})}
                                                            id="select"
                                                            onChange={handleChange}
                                                            className={'select'}
                                                            input={<OutlinedInput label="Business Type"/>}
                                                            MenuProps={MenuProps}
                                                        >
                                                            {types.map((type) => (
                                                                <MenuItem
                                                                    key={type}
                                                                    value={type}
                                                                    style={getStyles(type, personName, theme)}
                                                                >
                                                                    {type}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    <p className={'error'}>{errors.businessType?.type === 'required' && "Business Type is required"}</p>
                                                </Box>
                                                <Box sx={{position: 'relative'}}>
                                                    <FormControl className={'select-box'}>
                                                        <InputLabel>Business Industry</InputLabel>
                                                        <Select
                                                            value={personName2}
                                                            {...register('businessIndustry', {required: true})}
                                                            id="select2"
                                                            onChange={handleChange2}
                                                            className={'select'}
                                                            input={<OutlinedInput label="Business Industry"/>}
                                                            MenuProps={MenuProps}
                                                        >
                                                            {industrys.map((industry) => (
                                                                <MenuItem
                                                                    key={industry}
                                                                    value={industry}
                                                                    style={getStyles(industry, personName2, theme)}
                                                                >
                                                                    {industry}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    <p className={'error'}>{errors.businessIndustry?.type === 'required' && "Business Industry is required"}</p>
                                                </Box>
                                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    <Button
                                                        variant="contained"
                                                        onClick={handleSubmit(onSubmit)}
                                                        sx={{ width: '137px'}}
                                                        className={'btn next'}
                                                    >
                                                        Next
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </React.Fragment>
                                    )
                                    }

                                </React.Fragment>
                            )}
                        </React.Fragment>
                    </Box>
                </GoogleReCaptchaProvider>
            </Box>
        </Box>
    )
};